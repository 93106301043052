// Get inputs
const fieldInputs = document.querySelectorAll('.form-field__input');

// Loop inputs
fieldInputs.forEach(input => {

    // Event:focus
    input.addEventListener('focus', (event) => {

        // Get field
        const field = event.target.closest('.form-field');

        // Add class
        field.classList.add('is-active');
    });

    // Event:blur
    input.addEventListener('blur', (event) => {

        // Get field
        const field = event.target.closest('.form-field');

        if (field.classList.contains('is-success') || field.classList.contains('is-error')) {
            field.classList.remove('is-active');
        } else {
            field.classList.remove('is-active');
        }
    });
});