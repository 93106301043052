import { slideDown, slideUp, removeSomeClass } from './../Helpers';

const humburger = document.getElementById('humburger');
const header = document.getElementById('header');
const menu = document.getElementById('menu');

humburger.addEventListener('click', () => {

    if (humburger.classList.contains('is-active')) {
        humburger.classList.remove('is-active');
        slideUp(menu);
    } else {
        humburger.classList.add('is-active');
        slideDown(menu);
        setTimeout(() => {
            menu.style.height = `${window.innerHeight - header.offsetHeight + 1}px`;
            menu.style.overflow = 'auto';
            menu.style.maxHeight = 'none';
        }, 200);
    }
});

/* ==========================================================================
    Mobile menu
========================================================================== */
const submenu = document.querySelectorAll('.submenu');
const menuLinks = document.querySelectorAll('.menu > ul > li > a');

// Check window width
if (window.innerWidth <= 992) {

    // Event:click
    menuLinks.forEach(link => {
        link.addEventListener('click', (event) => {

            if (event.target.classList.contains('is-active')) {
                event.target.classList.remove('is-active');
                slideUp(event.target.nextElementSibling);
            } else {
                removeSomeClass(menuLinks, 'is-active');
                submenu.forEach(item => {
                    slideUp(item);
                });
                event.target.classList.toggle('is-active');
                slideDown(event.target.nextElementSibling);
            }
        });
    });
}