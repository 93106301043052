// Get elements
const bar = document.getElementById('progress-bar__line');

// Event scroll
if (bar) {
    window.addEventListener('scroll', progressBar);
}

// Progress bar
function progressBar() {
    const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (windowScroll / height) * 100;
    bar.style.width = scrolled + '%';
}