/* ==========================================================================
    Libraries
========================================================================== */
import objectFitImages from 'object-fit-images';
import svg4everybody from 'svg4everybody';
import lozad from 'lozad';
import Modals from './libraries/modal';
// import SmoothScroll from './libraries/smooth-scroll';
// import GLightbox from 'glightbox';
// import ScrollOut from 'scroll-out';

/* ==========================================================================
    Components
========================================================================== */
import './components/menu';
// import './components/sticky-element';
// import './components/form-file';
import './components/placeholder';
// import './components/send-form';
import './components/validate';
// import './components/map';
// import './components/select';
// import './components/scroll-up';

/* ==========================================================================
    Modules
========================================================================== */
import './modules/menu';
import './modules/tags';
import './modules/contacts';
import './modules/progress-bar';
import './modules/clients';

/* ==========================================================================
    Init libraries
========================================================================== */
objectFitImages();

svg4everybody();

const observer = lozad('[data-lazy]');
observer.observe();

Modals.init({
    backspaceClose: false,
    modalActiveClass: 'is-open',
    modalBGClass: 'modal-overlay',
    // callbackClose: function (toggle, modal) {}
});

// GLightbox({
//     selector: 'image--lightbox'
// });

// ScrollOut();

// new SmoothScroll('a[data-scroll]');