// Get elements
const tagsUp = document.getElementById('tags-up');
const tagsDown = document.getElementById('tags-down');
const list = document.querySelector('.tags__list');

// Check elements
if (tagsUp) {
    tagsUp.addEventListener('click', (event) => {
        sideScroll(list, 'top', 25, 100, 10);
    });

    tagsDown.addEventListener('click', (event) => {
        sideScroll(list, 'down', 25, 100, 10);
    });
}

function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;

    const slideTimer = setInterval(() => {
        if (direction === 'top') {
            element.scrollTop -= step;
        } else {
            element.scrollTop += step;
        }

        scrollAmount += step;

        if (scrollAmount >= distance) {
            window.clearInterval(slideTimer);
        }
    }, speed);
}