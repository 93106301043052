import Siema from 'siema';

// Get list
const menuList = document.querySelectorAll('.submenu__list');

// Check list
if (menuList) {

    menuList.forEach(list => {

        // Init slider
        const slider = new Siema({
            selector: list,
            perPage: {
                320: 1,
                576: 2,
                768: 3,
                992: 4,
                1200: 6
            },
            loop: false
        });

        // Get controls
        const prev = list.closest('.submenu').querySelector('.submenu__prev');
        const next = list.closest('.submenu').querySelector('.submenu__next');

        // Event click on controls
        prev.addEventListener('click', (event) => {
            slider.prev(1);
        });
        next.addEventListener('click', (event) => {
            slider.next(1);
        });
    });
}