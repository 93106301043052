import Siema from 'siema';

// Get list
const clientsList = document.getElementById('clients__list');

// Check list
if (clientsList) {

    // Check window width
    if (window.innerWidth <= 992) {

        clientsList.querySelectorAll('img').forEach(image => {
            const currentSrc = image.getAttribute('data-src');
            image.src = currentSrc;
        });

        // Init slider
        const slider = new Siema({
            selector: clientsList,
            perPage: 2,
            loop: true
        });

        setInterval(() => slider.next(), 5000);
    }
}