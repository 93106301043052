// Get elements
const contactsButton = document.getElementById('contacts-menu__button');
const root = document.getElementById('root');

// Check elements
if (contactsButton) {
	contactsButton.addEventListener('click', (event) => {
		root.classList.toggle('is-contacts-menu-overlay');
		contactsButton.closest('.contacts-menu').classList.toggle('is-active');
	});
	root.addEventListener('click', (event) => {
		if (event.target.classList.contains('is-contacts-menu-overlay')) {
			event.target.classList.remove('is-contacts-menu-overlay');
			contactsButton.closest('.contacts-menu').classList.remove('is-active');
		}
	});
}