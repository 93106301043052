import Pristine from './../libraries/validate';
import Modals from './../libraries/modal';
// import Mask from './../../../node_modules/input.numbered/numbered';
// import sendForm from './send-form';

const allForms = document.querySelectorAll('.form');

allForms.forEach(form => {

    // Init form validator
    const pristine = new Pristine(form, {
        classTo: 'form-field',
        errorClass: 'is-error',
        successClass: 'is-success',
        errorTextParent: 'form-field',
        errorTextTag: 'span',
        errorTextClass: 'error-message'
    }, true);

    // Event submit on form
    form.addEventListener('submit', (event) => {
        event.preventDefault();
        pristine.validate();
    });
});